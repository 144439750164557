<template>
    <div id="app">
        <TaskManager />
    </div>
</template>

<script>
import TaskManager from '@/components/Mapping/TaskManager';

export default {
    components: {
        TaskManager,
    }
}
</script>